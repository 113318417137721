import React from "react"

import { setCSSVariables } from "../utils/setCSSVariables"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  cart: [],
  selectedFilter: "",
  initialLoad: false,
  discount: 0,
  theme: {
    primary: "#ffffff",
    secondary: "#000000",
    accent: "#00ffff",
  },
}

let localState = {
  cart: [],
  selectedFilter: "",
  initialLoad: false,
  discount: 0,
  theme: {
    primary: "#ffffff",
    secondary: "#000000",
    accent: "#00ffff",
  },
}

if (typeof window !== "undefined") {
  localState = JSON.parse(localStorage.getItem("cart"))
} else {
  localState = initialState
}

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ITEM": {
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            product: action.payload.product,
          },
        ],
      }
    }
    case "REMOVE_ITEM": {
      state.cart.splice(action.payload.index, 1)
      return {
        ...state,
        cart: [...state.cart],
      }
    }
    case "CLEAR_CART": {
      return {
        ...state,
        cart: [],
      }
    }
    case "ADD_DISCOUNT": {
      return {
        ...state,
        discount: action.payload.discount,
      }
    }
    case "REMOVE_DISCOUNT": {
      return {
        ...state,
        discount: 0,
      }
    }
    case "SELECT_FILTER": {
      return {
        ...state,
        cart: [...state.cart],
        selectedFilter: action.payload.selectedFilter,
      }
    }
    case "INITIAL_LOAD": {
      return {
        ...state,
        initialLoad: action.payload.initialLoad,
      }
    }
    case "SET_THEME": {
      return {
        ...state,
        theme: {
          primary: action.payload.primary,
          secondary: action.payload.secondary,
          accent: action.payload.accent,
          error: action.payload.error,
        },
      }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    localState || initialState
  )

  // Set cart equal to state in local storage
  React.useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state))
  }, [state])

  // Update theme as context changes
  React.useEffect(() => {
    setCSSVariables(state.theme)
  }, [state])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
