import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"
import ReactDOM from "react-dom"

require("typeface-assistant")
require("typeface-space-mono")

require("./src/css/layout.css")
require("./src/css/typography.css")
require("./src/css/desktop-styles.css")
require("./src/css/mobile-styles.css")

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
